<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Form</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group pt-2">
                                <label for="inputEmail">Email address</label>
                                <input class="form-control" id="inputEmail" type="email" placeholder="Enter email">
                            </div>
                            <div class="form-group">
                                <label for="inputPassword">Password</label>
                                <input class="form-control" id="inputPassword" type="password" placeholder="Password">
                            </div>
                            <div class="row pt-3">
                                <div class="col-lg-6 pb-4 pb-lg-0">
                                    <div class="be-checkbox custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check1">
                                        <label class="custom-control-label" for="check1">Remember me</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <button class="btn btn-space btn-secondary mr-1">Cancel</button>
                                    <button class="btn btn-space btn-primary" type="submit">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Horizontal Form</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row mt-2">
                                <label class="col-3 col-lg-2 col-form-label text-right" for="inputEmail2">Email</label>
                                <div class="col-9 col-lg-10">
                                    <input class="form-control" id="inputEmail2" type="email" placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-3 col-lg-2 col-form-label text-right" for="inputPassword2">Password</label>
                                <div class="col-9 col-lg-10">
                                    <input class="form-control" id="inputPassword2" type="password" placeholder="Password">
                                </div>
                            </div>
                            <div class="row pt-3 mt-1">
                                <div class="col-sm-6 col-lg-6 pb-4 pb-lg-0">
                                    <div class="be-checkbox custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check2">
                                        <label class="custom-control-label" for="check2">Remember me</label>
                                    </div>
                                </div>
                                <div class="col-sm-6 text-right">
                                    <button class="btn btn-space btn-secondary mr-1">Cancel</button>
                                    <button class="btn btn-space btn-primary" type="submit">Register</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Elements</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputText3">Input Text</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputText3" type="text">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputPassword3">Input Password</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputPassword3" type="password">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputPlaceholder3">Placeholder Input</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputPlaceholder3" type="text" placeholder="Placeholder text">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputPlaceholder4">Help text</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputPlaceholder4" type="password" placeholder="*****">
                                    <small class="form-text text-muted">
                                        Your password must be 8-20 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
                                    </small>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputDisabled3">Disabled Input</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputDisabled3" type="text" disabled="disabled" placeholder="Disabled input text">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputReadonly3">Readonly Input</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputReadonly3" type="text" readonly="readonly" value="Readonly input text">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputTextarea3">Textarea</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <textarea class="form-control" id="inputTextarea3"></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Input File</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="file-1">Button File Input</label>
                                <div class="col-12 col-sm-6">
                                    <div class="custom-file">
                                        <input type="file" class="custom-file-input" id="customFile">
                                        <label class="custom-file-label" for="customFile">Choose file</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Custom Button File Input</label>
                                <div class="col-12 col-sm-6">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text" id="inputGroupFileAddon01">Upload</span></div>
                                        <div class="custom-file">
                                            <input class="custom-file-input" id="inputGroupFile01" type="file" aria-describedby="inputGroupFileAddon01">
                                            <label class="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="custom-file">
                                            <input class="custom-file-input" id="inputGroupFile02" type="file">
                                            <label class="custom-file-label" for="inputGroupFile02" aria-describedby="inputGroupFileAddon02">Choose file</label>
                                        </div>
                                        <div class="input-group-append"><span class="input-group-text" id="inputGroupFileAddon02">Upload</span></div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <button class="btn btn-secondary" id="inputGroupFileAddon03" type="button">Button</button>
                                        </div>
                                        <div class="custom-file">
                                            <input class="custom-file-input" id="inputGroupFile03" type="file" aria-describedby="inputGroupFileAddon03">
                                            <label class="custom-file-label" for="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div>
                                    <div class="input-group">
                                        <div class="custom-file">
                                            <input class="custom-file-input" id="inputGroupFile04" type="file" aria-describedby="inputGroupFileAddon04">
                                            <label class="custom-file-label" for="inputGroupFile04">Choose file</label>
                                        </div>
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" id="inputGroupFileAddon04" type="button">Button</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Input Sizing<span class="card-subtitle">These are the different form control component sizes</span></div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputLarge">Large</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control form-control-lg" id="inputLarge" type="text" value="Large input">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputDefault">Default</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control" id="inputDefault" type="text" value="Default input">
                                </div>
                            </div>
                            <div class="form-group row align-items-center">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right" for="inputSmall">Small</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control form-control-sm" id="inputSmall" type="text" value="Small input">
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Select and Option Elements</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Checkbox</label>
                                <div class="col-12 col-sm-8 col-lg-6 mt-1">
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" checked="" id="check3">
                                        <label class="custom-control-label" for="check3">Option 1</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check4">
                                        <label class="custom-control-label" for="check4">Option 2</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check5">
                                        <label class="custom-control-label" for="check5">Option 3</label>
                                    </div>
                                    <div class="custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" id="check6" disabled>
                                        <label class="custom-control-label" for="check6">Disabled</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Inline Checkbox</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" checked="" id="check7">
                                        <label class="custom-control-label" for="check7">Option 1</label>
                                    </div>
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" id="check8">
                                        <label class="custom-control-label" for="check8">Option 2</label>
                                    </div>
                                    <div class="custom-control custom-checkbox custom-control-inline">
                                        <input class="custom-control-input" type="checkbox" id="check9">
                                        <label class="custom-control-label" for="check9">Option 3</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Radio</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <label class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="radio-stacked" checked=""><span class="custom-control-label">Option 1</span>
                                    </label>
                                    <label class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="radio-stacked"><span class="custom-control-label">Option 2</span>
                                    </label>
                                    <label class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="radio-stacked"><span class="custom-control-label">Option 3</span>
                                    </label>
                                    <label class="custom-control custom-radio">
                                        <input class="custom-control-input" type="radio" name="radio-stacked" disabled><span class="custom-control-label">Disabled</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Inline Radio</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="radio-inline" checked=""><span class="custom-control-label">Option 1</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="radio-inline"><span class="custom-control-label">Option 2</span>
                                    </label>
                                    <label class="custom-control custom-radio custom-control-inline">
                                        <input class="custom-control-input" type="radio" name="radio-inline"><span class="custom-control-label">Option 3</span>
                                    </label>
                                </div>
                            </div>
                            <div class="form-group row pt-1 pb-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Switches</label>
                                <div class="col-12 col-sm-8 col-lg-6 form-check mt-1">
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" id="customSwitch1">
                                        <label class="custom-control-label" for="customSwitch1">Toggle this switch element</label>
                                    </div>
                                    <div class="custom-control custom-switch">
                                        <input type="checkbox" class="custom-control-input" disabled id="customSwitch2">
                                        <label class="custom-control-label" for="customSwitch2">Disabled switch element</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row pt-1">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Basic Select</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <select class="custom-select">
                                        <option value="1" selected="">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Multiple Select</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <select class="custom-select" multiple="">
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Range Element</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group">
                                <label for="customRange1">Example range</label>
                                <input type="range" class="custom-range" id="customRange1">
                            </div>
                            <div class="form-group">
                                <label for="customRange2">Example range</label>
                                <input type="range" class="custom-range" min="0" max="5" id="customRange2">
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Validation States</div>
                    <div class="card-body">
                        <form action="#">
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Success</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control is-valid" type="text" required="">
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Error</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control is-invalid" type="text" required="">
                                </div>
                            </div>
                            <div class="card-divider"></div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Input group success state</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <div class="input-group input-group-lg mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                                        <input class="form-control is-valid" type="text" placeholder="" aria-describedby="inputGroupPrepend3" required="">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Input group invalid state</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <div class="input-group input-group-lg mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                                        <input class="form-control is-invalid" type="text" placeholder="" aria-describedby="inputGroupPrepend3" required="">
                                    </div>
                                </div>
                            </div>
                            <div class="card-divider"> </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Basic success state</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control is-valid" type="text" placeholder="" value="" required="">
                                    <div class="valid-feedback">Looks good!</div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Basic invalid state</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <input class="form-control is-invalid" type="text" placeholder="" value="" required="">
                                    <div class="invalid-feedback">Invalid!</div>
                                </div>
                            </div>
                            <div class="form-group row is-valid">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Tooltip success state</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <label for="validationTooltip01">First name</label>
                                    <input type="text" class="form-control is-valid" id="validationTooltip01" value="Mark" required>
                                    <div class="valid-tooltip">
                                        Looks good!
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row is-valid">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Tooltip invalid state</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <label for="validationTooltip02">First name</label>
                                    <input type="text" class="form-control is-invalid" id="validationTooltip02" value="Mark" required>
                                    <div class="invalid-tooltip">
                                        Invalid!
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Input Groups</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Input Text</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <div class="input-group mb-3"><span class="input-group-prepend"><span class="input-group-text">@</span></span>
                                        <input class="form-control" type="text" placeholder="Username">
                                    </div>
                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append"><span class="input-group-text">.00</span></div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text">$</span></div>
                                        <input class="form-control" type="text">
                                        <div class="input-group-append"><span class="input-group-text">.00</span></div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Input Sizing</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <div class="input-group input-group-lg mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                                        <input class="form-control" type="text" placeholder="Username">
                                    </div>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                                        <input class="form-control" type="text" placeholder="Username">
                                    </div>
                                    <div class="input-group input-group-sm mb-3">
                                        <div class="input-group-prepend"><span class="input-group-text">@</span></div>
                                        <input class="form-control" type="text" placeholder="Username">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Checkbox &amp; Radio</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <div class="custom-control custom-checkbox">
                                                    <input class="custom-control-input" type="checkbox" id="check13">
                                                    <label class="custom-control-label" for="check13"></label>
                                                </div>
                                            </div>
                                        </div>
                                        <input class="form-control" type="text">
                                    </div>
                                    <div class="input-group mb-2">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text">
                                                <label class="custom-control custom-radio mb-0">
                                                    <input class="custom-control-input" type="radio"><span class="custom-control-label"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <input class="form-control" type="text">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label class="col-12 col-sm-3 col-form-label text-sm-right">Button Addons</label>
                                <div class="col-12 col-sm-8 col-lg-6">
                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append">
                                            <button class="btn btn-primary" type="button">Go!</button>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append be-addon">
                                            <button class="btn btn-primary dropdown-toggle" type="button" data-toggle="dropdown">Action</button>
                                            <div class="dropdown-menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="input-group mb-3">
                                        <input class="form-control" type="text">
                                        <div class="input-group-append be-addon">
                                            <button class="btn btn-primary" tabindex="-1" type="button">Action</button>
                                            <button class="btn btn-primary dropdown-toggle dropdown-toggle-split" tabindex="-1" data-toggle="dropdown" type="button"><span class="sr-only">Toggle Dropdown</span></button>
                                            <div class="dropdown-menu"><a class="dropdown-item" href="#">Action</a><a class="dropdown-item" href="#">Another action</a><a class="dropdown-item" href="#">Something else here</a>
                                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FormElements',
    }
</script>
